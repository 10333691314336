import { useState, React, useContext } from "react";
import "../style/programs.css";
import programs from "../data/programs.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { PackageContext } from "./PackageContext";
import {
  faDumbbell,
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import Data from "../data/programs.json";
import Slide from "react-reveal/Slide";
export default function Programs(props) {
  const [Package, setPackage] = useContext(PackageContext);
  console.log(Package);
  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  const [subClicked, setSubClicked] = useState(false);
  const handleApply = (id) => {
    const selectedPackage = Data.filter((dat) => {
      // eslint-disable-next-line
      return dat.id == id.toString().split(".")[0];
    });
    setPackage(selectedPackage);
    history.push("/apply/" + id);
  };
  const handlesub = (index) => {
    if (subClicked === index) {
      return setSubClicked(null);
    }
    setSubClicked(index);
  };
  const handletoggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };
  return (
    <side>
      {programs.map((program) => {
        return (
          <div className="programs" key={program.id}>
            <div className="arrowdown" onClick={() => handletoggle(program.id)}>
              <h1>{program.programName}</h1>
              <h1>
                {clicked === program.id ? (
                  <FontAwesomeIcon
                    className="downarrow"
                    icon={faAngleDoubleUp}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="arrowup"
                    icon={faAngleDoubleDown}
                  />
                )}{" "}
              </h1>
            </div>
            <p className="description">
              Description:
              <br /> {program.Description}
            </p>
            {clicked === program.id
              ? program.subProgram.map((list) => {
                  return (
                    <Slide left cascade>
                      <div className="sublist" key={list.id}>
                        <h4
                          className="sublistheading"
                          onClick={() => handlesub(program.id)}
                        >
                          {list.name}
                        </h4>
                        {list.items.map((item) => {
                          return (
                            <li>
                              {" "}
                              <FontAwesomeIcon
                                icon={faDumbbell}
                                className="dumbell"
                              />{" "}
                              {item.list}
                            </li>
                          );
                        })}
                        <button
                          className="applybutton"
                          onClick={() => handleApply(list.id)}
                        >
                          Apply
                        </button>
                      </div>
                    </Slide>
                  );
                })
              : null}
          </div>
        );
      })}
    </side>
  );
}
