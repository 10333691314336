import * as React from 'react';
import '../style/controlPanel.css'


function ControlPanel(props) {

  return (
    <div className="control-panel">
        <h1>Clients Trained</h1>
    </div>
  );
}

export default React.memo(ControlPanel);