// import CoupleCooperation from "../images/CoupleCooperation.jpeg";
// import CouplecoPushUps from "../images/CouplecoPushUps.jpeg";
// import CoupleGluteWorkout from "../images/CoupleGluteWorkout.jpeg";
// import CoupleLatWorkout from "../images/CoupleLatWorkout.jpeg";
// import CouplePose from "../images/CouplePose.jpeg";
// import CouplePushup from "../images/CouplePushup.jpeg";
// import Couplesitups from "../images/Couplesitups.jpeg";
// import CoupleStandoff from "../images/CoupleStandoff.jpeg";
// import CoupleStaring from "../images/CoupleStaring.jpeg";
// import coupleWorkout from "../images/coupleWorkout.jpeg";
// import DumbbellColorful from "../images/DumbbellColorful.jpeg";
// import dumbbell from "../images/dumbbell.jpeg";
// import Equipments from "../images/Equipments.jpeg";
// import Girls from "../images/Girls.jpeg";
// // import personOld from "../images/personOld.jpeg";
// import customer1 from "../images/customer.jpg";
import cutomer2 from "../images/customer2.jpg";
import customer3 from "../images/customer3.jpg";
import customer4 from "../images/customer4.jpg";
import customer5 from "../images/customer5.jpg";
import femletrainer from "../images/femaletrainer.jpg";
import trainer2 from "../images/trainer2.jpg";
import trainer3 from "../images/trainer3.jpg";
import trainer from "../images/trainer.jpg";
import trainer1 from "../images/trainer1.jpg";
import trainer4 from "../images/trainer4.jpg";

export const gallaryImageData = [
  // {
  //   title: "Information Techonlogy Security Support",
  //   path: CoupleCooperation,
  // },
  // {
  //   title: "Company Two",
  //   path: CouplecoPushUps,
  // },
  // {
  //   title: "Company three",
  //   path: CouplePose,
  // },
  // {
  //   title: "Company three",
  //   path: CoupleGluteWorkout,
  // },
  // {
  //   title: "Company three",
  //   path: CoupleLatWorkout,
  // },

  // {
  //   title: "Company three",
  //   path: CouplePushup,
  // },
  // {
  //   title: "Company three",
  //   path: Couplesitups,
  // },
  // {
  //   title: "Company three",
  //   path: CoupleStandoff,
  // },
  // {
  //   title: "Company three",
  //   path: CoupleStaring,
  // },
  // {
  //   title: "Company three",
  //   path: DumbbellColorful,
  // },
  // {
  //   title: "Company three",
  //   path: coupleWorkout,
  // },
  // {
  //   title: "Company three",
  //   path: dumbbell,
  // },
  // {
  //   title: "Company three",
  //   path: Equipments,
  // },
  // {
  //   title: "Company three",
  //   path: Girls,
  // },
  // { title: "company three", path: customer1 },
  // // {
  // //   title: "Company three",
  // //   path: personOld,
  // // },

  { title: "company three", path: cutomer2 },
  { title: "company three", path: customer4 },
  { title: "company three", path: trainer2 },
  { title: "company three", path: customer5 },
  { title: "company three", path: customer3 },
  { title: "company three", path: femletrainer },
  { title: "company three", path: trainer3 },
  { title: "company three", path: trainer },
  { title: "company three", path: trainer1 },
  { title: "company three", path: trainer4 },
];
