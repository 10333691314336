import React from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Contactus from "./components/Contactus";
import Aboutus from "./components/Aboutus";
import Programs from "./components/Programs";
import Apply from "./components/Apply";
import Gallary from "./components/Gallary";
import PageNotfound from "./components/PageNotFound";
import { PackageContextProviders } from "./components/PackageContext";
import { Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="mainbody">
      <Header />
      <PackageContextProviders>
        <Switch>
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/programs" component={Programs} />
          <Route exact path="/apply/:id" component={Apply} />
          <Route exact path="/gallary" component={Gallary} />
          <Route exact path="/" component={Home} />
          <Route component={PageNotfound} />
        </Switch>
      </PackageContextProviders>
    </div>
  );
}

export default App;
