import React, { useState } from "react";

export const PackageContext = React.createContext();

export const PackageContextProviders = (props) => {
  const [Package, setPackage] = useState([]);
  return (
    <PackageContext.Provider value={[Package, setPackage]}>
      {props.children}
    </PackageContext.Provider>
  );
};
