import React from "react";
import Footer from "./Footer";
import Slide from "react-reveal/Slide";
import "../style/home.css";
import HomeSlider from "./HomeSlider";
import { useHistory } from "react-router-dom";
export default function Home() {
  const history = useHistory();
  const handleSeemoreClicked = () => {
    history.push("/programs");
  };

  return (
    <>
      <div className="slidercont">
        <HomeSlider />
      </div>
      <main>
        <Slide left>
          <div className="prog progone">
            <h1>
              PRO-FIT <span>DISABILITY</span> FITNESS PROGRAM
            </h1>
            <p className="desc">
            <h4>Description:</h4>
              Our disability fitness program is uniquely designed and tailored
              towards each individuals abilities and long term goals. We focus
              on variety of aspects when it comes to ones overall health goals.
              <p className="seemore" onClick={handleSeemoreClicked}>
                See More...
              </p>
            </p>
          </div>
        </Slide>
        <div className="prog progtwo">
          <h1>
            PRO-FIT <span>PERSONAL</span> TRAINING PROGRAM
          </h1>
          <p className="desc">
            <h4>Description:</h4>
            Our personal training program guarantees the ultimate 1on1 fitness
            program experience for anyone who is looking to improve their
            overall lifestyle and health and or fitness goals. 
            <p className="seemore" onClick={handleSeemoreClicked}>
              See More...
            </p>
          </p>
        </div>
        <Slide right>
          <div className="prog progthree">
            <h1>
              PRO-FIT <span>VIRTUAL</span> TRAINING PROGRAMS
            </h1>
            <p className="desc">
              <h4>Description:</h4> Stay active and join us for a fun and
              exciting virtual PRO-FIT fitness class right in the comfort of
              your own home. There are workouts tailored for everyone from
              beginners to advanced fitness levels. Stay connected and keep
              moving with us!
              <p className="seemore" onClick={handleSeemoreClicked}>
                See More...
              </p>
            </p>
          </div>
        </Slide>
      </main>
      <Footer />
    </>
  );
}
