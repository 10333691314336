import * as React from "react";
import { useState } from "react";
import ControlPanel from "./ControlPanel";

import ReactMapGL, { Popup, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pins from "./pins";
import MemberInfo from "./MemberInfo";
import "../style/contactus.css";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import memberData from "../data/members.json";
export default function Contactus() {
  const [viewport, setViewport] = useState({
    width: 700,
    height: 500,
    latitude: 44.0,
    longitude: -79.0,
    zoom: 7,
  });
  const settings = {
    dragPan: true,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 3,
    maxZoom: 8,
  };

  const handleSocialMediaClick = (link) => {
    window.open(link, "_blank");
  };
  const navControlStyle = {
    left: 10,
    top: 10,
  };

  const [popupInfo, setPopupInfo] = useState(null);
  return (
    <div className="contactUsMain">
      <div className="Mapbox">
        <ReactMapGL
          {...viewport}
          {...settings}
          mapStyle="mapbox://styles/mapbox/dark-v9"
          mapboxApiAccessToken={
            "pk.eyJ1IjoiaXRzc2N0byIsImEiOiJja3AyZXF3YzkweDV6MnZ0ZWlybDltZjhhIn0.Q6-NrYiizpfKP11JRtMbrA"
          }
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
        >
          <Pins data={memberData} onClick={setPopupInfo} />

          {popupInfo && (
            <Popup
              tipSize={10}
              anchor="top"
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              closeOnClick={false}
              onClose={setPopupInfo}
            >
              <MemberInfo info={popupInfo} />
            </Popup>
          )}
          <NavigationControl style={navControlStyle} />
          <ControlPanel />
        </ReactMapGL>
      </div>
      <div className="contactusinfo">
        <h1>Contact US</h1>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:prolificfw@gmail.com">prolificfw@gmail.com</a>
          </li>
          <li>Phone: 647-964-7017</li>
          <li className="socialMediaList">
            Social Media:
            <FontAwesomeIcon
              className="socialMedia"
              onClick={() => {
                handleSocialMediaClick(
                  "https://www.instagram.com/prolific_fw/"
                );
              }}
              icon={faInstagram}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
