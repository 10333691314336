import React from "react";
import pic from "../images/founder.jpg";
import "../style/aboutus.css";
import Fade from "react-reveal/Fade";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Aboutus() {
  return (
    <Fade left cascade>
      <div className="contactuspage">
        <h1>Why this industry?</h1>
        <p>
          I chose to be in this industry because there is no better feeling than
          being part of someone’s health and fitness journey. To witness
          clients, reach their goals and be the best version of themselves is
          one of the most rewarding feelings. I want my coaching/training
          philosophy and programs to have a positive influential impact to
          change your life.
        </p>
        <h1>
          <FontAwesomeIcon className="goalIcon" icon={faBullseye} /> Our
          Goal/Mission:
        </h1>
        <p>
          Here at Prolific Fitness and Wellness it’s our goal to empower
          individuals health and wellness by implementing ideal fun fitness
          routines to reach their maximum potential. We want to see our clients
          look their best to feel their best but also feel comfortable and
          confident when they workout with us. We aim to teach more than just
          workouts, we focus on teaching skills one can use to further improve
          their life skills.
        </p>
        <h1>Founder Profile</h1>
        <div className="founderDiv">
          <div className="founderpic">
            <img src={pic} alt="founder" />
          </div>
          <div>
            <h1 className="founderName">Milad Sayer</h1>
            <h3 className="foundertitle">
              Founder/CEO of Prolific Fitness and wellness corp.
            </h3>
            <p className="founderMessage">
              After graduating from the HWF program at Mohawk in 2016. I
              immediately began working in the health and Fitness industry I
              have been specializing in working with individuals with
              intellectual and physical disabilities for 3 years now.
            </p>
          </div>
        </div>
      </div>
    </Fade>
  );
}
