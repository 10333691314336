import React from "react";
import "../style/memberinfo.css";
export default function MemberInfo(props) {
  const { info } = props;
  const displayName = `${info.name}`;
  const address = `${info.address}`;
  return (
    <div className="memberinfo">
      <div>{displayName}</div>
      <div>{address}</div>
    </div>
  );
}
