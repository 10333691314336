import CoupleCooperation from "../images/CoupleCooperation.jpeg";
import CouplecoPushUps from "../images/CouplecoPushUps.jpeg";
import CoupleGluteWorkout from "../images/CoupleGluteWorkout.jpeg";
import CoupleLatWorkout from "../images/CoupleLatWorkout.jpeg";
import CouplePushup from "../images/CouplePushup.jpeg";
import Couplesitups from "../images/Couplesitups.jpeg";
import CoupleStandoff from "../images/CoupleStandoff.jpeg";
import CoupleStaring from "../images/CoupleStaring.jpeg";
import coupleWorkout from "../images/coupleWorkout.jpeg";
import Girls from "../images/Girls.jpeg";

export const homeSliderdata = [
  {
    title: "Information Techonlogy Security Support",
    path: CoupleCooperation,
  },
  {
    title: "Company Two",
    path: CouplecoPushUps,
  },
  {
    title: "Company three",
    path: CoupleGluteWorkout,
  },
  {
    title: "Company three",
    path: CoupleLatWorkout,
  },

  {
    title: "Company three",
    path: CouplePushup,
  },
  {
    title: "Company three",
    path: Couplesitups,
  },
  {
    title: "Company three",
    path: CoupleStandoff,
  },
  {
    title: "Company three",
    path: CoupleStaring,
  },
  {
    title: "Company three",
    path: Girls,
  },
  {
    title: "Company three",
    path: coupleWorkout,
  },
];
