import { React, useState, useContext, useEffect } from "react";
import emailjs from "emailjs-com";
import { PackageContext } from "./PackageContext";
import "../style/apply.css";
import { Redirect } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Apply(props) {
  // eslint-disable-next-line
  const [Package, setPackage] = useContext(PackageContext);

  //   console.log(props.match.params.id);
  const [sub, setSub] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line
    Package.map((pack) => {
      // eslint-disable-next-line
      pack.subProgram.filter(function (selectedlisted) {
        // eslint-disable-next-line
        if (selectedlisted.id == props.match.params.id) {
          return setSub(selectedlisted.name);
        }
      });
    });
  }, [props.match.params.id, Package]);
  const [person, setPerson] = useState({
    fname: "",
    lname: "",
    email: "",
    // age: "",
    mainPackage: "",
    sub: "",
    price: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  };

  const handleApply = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3mtfoik",
        "template_lsycftg",
        e.target,
        "user_gVzTPBsJZ0J6JLX12Zgli"
      )
      .then(
        (result) => {
          console.log(e.target);
          console.log(result.text);
          toast.dark("Email has Been Send Successfully , Check Your Mail Box");
        },
        (error) => {
          console.log(e.target);
          console.log(error.text);
          toast.dark(error.text);
        }
      );
  };
  // eslint-disable-next-line
  if (Package == "") return <Redirect to="/programs" push="false" />;
  // eslint-disable-next-line
  if (props.match.params.id == "")
    return <Redirect to="/programs" push="false" />;
  // eslint-disable-next-line
  if (Package[0].id != props.match.params.id.toString().split(".")[0]) {
    console.log(props.match.params.id.toString().split(".")[0]);
    console.log(Package[0].id);
    return <Redirect to="/programs" push="false" />;
  }
  /*jshint ignore:end*/
  return (
    <>
      <div className="mainApply">
        <form className="applysection" onSubmit={handleApply}>
          <div className="programdet">
            <h1>Selected Program Details</h1>
            <label htmlFor="mainPackage">Main Package </label>
            {Package.map((pack) => {
              return (
                <input
                  className="programdetlist"
                  value={pack.programName}
                  type="text"
                  name="mainPackage"
                />
              );
            })}
            <label htmlFor="sub">Sub Package </label>
            <input
              className="programdetlist"
              value={sub}
              type="text"
              name="sub"
            />
          </div>
          <div className="inputform">
            <label htmlFor="name">First Name</label>
            <input
              value={person.name}
              onChange={handleChange}
              type="text"
              name="fname"
            />
            <label htmlFor="lname">Last Name</label>
            <input
              value={person.lname}
              onChange={handleChange}
              type="text"
              name="lname"
            />
            <label htmlFor="cphone">Cellphone Number</label>
            <input
              value={person.cphone}
              onChange={handleChange}
              type="text"
              name="cphone"
            />
            {/* <label htmlFor="age">Age</label>
          <input
            value={person.age}
            onChange={handleChange}
            type="number"
            name="age"
          /> */}
            <label htmlFor="email"> Email </label>
            <input
              value={person.email}
              onChange={handleChange}
              type="email"
              name="email"
            />
            <button className="applybutton" type="submit">
              Apply
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
