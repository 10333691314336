import React from "react";
import { useState } from "react";
import { faDumbbell, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";
export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  const handletoggle = () => {
    setNavOpen(!navOpen);
  };
  const renderClasses = () => {
    let cleasses = "navigation";
    if (navOpen) {
      cleasses += " active";
    }
    return cleasses;
  };
  return (
    <div>
      <div className="humbermenuicon">
        <FontAwesomeIcon
          onClick={handletoggle}
          className="fa-2x"
          icon={navOpen ? faTimes : faBars}
        />
      </div>
      <div onClick={handletoggle} className={renderClasses()}>
        <Fade left>
          <ul className="navbar">
            <NavLink exact to="/" activeClassName="selected">
              <li>
                <FontAwesomeIcon className="downarrow" icon={faDumbbell} />
                {"  "}
                Home
              </li>
            </NavLink>
            <NavLink exact to="/programs" activeClassName="selected">
              <li>
                <FontAwesomeIcon className="downarrow" icon={faDumbbell} />
                {"  "}Programs
              </li>
            </NavLink>
            <NavLink exact to="/gallary" activeClassName="selected">
              <li>
                <FontAwesomeIcon className="downarrow" icon={faDumbbell} />
                {"  "}Gallery
              </li>
            </NavLink>
            <NavLink exact to="/aboutus" activeClassName="selected">
              <li>
                <FontAwesomeIcon className="downarrow" icon={faDumbbell} />
                {"  "}About Us
              </li>
            </NavLink>
            <NavLink exact to="/contactus" activeClassName="selected">
              <li>
                <FontAwesomeIcon className="downarrow" icon={faDumbbell} />
                {"  "}Contact Us
              </li>
            </NavLink>
          </ul>
        </Fade>
      </div>
    </div>
  );
}
