import React from "react";
import { slidesdata } from "../data/sliderdata";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../style/footer.css";
export default function Footer() {
  const handlePartnerClick = (link) => {
    if(link){
      window.open(link, "_blank");
    }
  };
  return (
    <>
      <footer>
          <h1>Partners</h1>
        <Slider infinite={true} autoplay="60s" duration="4000">
          {slidesdata.map((slide, index) => {
            return (
              <div
              
              className="slider"
              key={index}
              >
                <div className="descriptions2">
                  <h4 className="slidertitle">{slide.title}</h4>
                  <h5>{slide.fullname}</h5>
                </div>
                <div className="sliderimage">
                  <img src={slide.path} id={slide.id} onClick={() => handlePartnerClick(slide.link)} alt="carousel" />
                </div>
              </div>
            );
          })}
        </Slider>
      </footer>
    </>
  );
}
