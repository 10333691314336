import React from "react";
import pic from "../prolificlogo.png";

import Fade from "react-reveal/Fade";
import "../style/header.css";
import Navbar from "./Navbar";
export default function Header() {
  return (
    <div>
      <nav>
        <div className="logoandslogongroup">
          <div className="logoimg">
            <img src={pic} alt="logo" />
          </div>
          <Fade left>
            <div>
              <h1 className="slogan">In Pursuit of Good Health</h1>
            </div>
          </Fade>
        </div>
        <Navbar />
      </nav>
    </div>
  );
}
