import icLogo from "../images/ImagineConsultationsLogo.png";
import itssLogo from "../images/itssLogo.jpeg";
import acceioLogo from '../images/ACCEIO_logo.jpeg'
// import networkplaner from '../images/Partner_Link_Button_Style2_HalfBanner.jpg'
import networkpalner2 from '../images/Partner_Link_Button_Style2_Square.jpg'
export const slidesdata = [
  {
    title: "ITSS",
    path: itssLogo,
    fullname: "Information Technology Skill Set",
    link: "",
  },
  {
    title: "Imagine Consultations",
    fullname: "Your Path | Your Choice | Your Future",
    path: icLogo,
    link: "https://www.imagineconsultations.com/",
  },
  {
    title: "ACCEIO",
    fullname: "Afghan Cultural & Eductional International Orgranization",
    path: acceioLogo,
    link:""
  },  {
    title: "Planning Network",
    fullname: "smart strategies, creative tools, sustainable solutions",
    id: "planer",
    path: networkpalner2,
    link:"http://www.planningnetwork.ca"
  },
];
