import React from "react";
import "../style/gallary.css";
import Fade from "react-reveal/Fade";
import { gallaryImageData } from "../data/gallaryImageData";
export default function Gallary() {
  return (
    <div className="gallaryMain">
      <div className="gallary">
        {gallaryImageData.map((pics, index) => {
          return (
            <>
              <div className="gallarypics">
                <Fade left opposite>
                  <img src={pics.path} alt={pics.title} />
                </Fade>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
